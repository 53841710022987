var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "card card-custom"
  }, [_c('div', {
    staticClass: "card-header border-0 pt-6 pb-0"
  }, [_c('div', {
    staticClass: "card-title align-items-start flex-column"
  }, [_c('h3', {
    staticClass: "card-label font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t("Address")) + " ")]), _c('span', {
    staticClass: "text-muted font-weight-bold font-size-sm mt-1"
  }, [_vm._v(_vm._s(_vm.$t("Update your addresses")))])]), _c('div', {
    staticClass: "card-toolbar"
  }, [_c('button', {
    staticClass: "btn btn-light-primary font-weight-bolder py-3",
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.openCreateDialog.apply(null, arguments);
      }
    }
  }, [_c('span', {
    staticClass: "svg-icon menu-icon"
  }, [_c('inline-svg', {
    attrs: {
      "src": "/media/svg/icons/Files/File.svg"
    }
  })], 1), _vm._v(" " + _vm._s(_vm.$t("New address")) + " ")])])]), _c('div', {
    staticClass: "card-body detail"
  }, [_c('address-table', {
    attrs: {
      "items": _vm.physicalPerson.physicalpersonaddress_set,
      "kinds": _vm.addressKinds
    },
    on: {
      "edit": _vm.openEditDialog,
      "remove": _vm.openRemoveDialog
    }
  })], 1)]), _c('validation-observer', {
    ref: "validationObserver"
  }, [_c('b-modal', {
    ref: "upsertModal",
    attrs: {
      "size": "lg",
      "hide-footer": ""
    },
    on: {
      "hidden": _vm.onUpsertModalHidden
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "w-100 h-100 d-flex justify-content-between align-items-center px-4 py-1"
        }, [_c('h3', [_vm._v(_vm._s(_vm.$t("Select an address")))]), _c('div', {
          staticClass: "d-flex align-items-center"
        }, [!_vm.disabled ? _c('b-button', {
          staticClass: "mr-2",
          class: {
            'spinner spinner-light spinner-right': _vm.isSaving
          },
          attrs: {
            "variant": "success"
          },
          on: {
            "click": _vm.submit
          }
        }, [_vm._v(" " + _vm._s(_vm.upsertItem.id ? _vm.$t("Update") : _vm.$t("Create")) + " ")]) : _vm._e(), _c('b-button', {
          on: {
            "click": _vm.closeUpsertModal
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('Cancel')) + " ")])], 1)])];
      },
      proxy: true
    }])
  }, [_c('AddressForm', {
    attrs: {
      "form": _vm.upsertItem,
      "kinds": _vm.addressKinds
    },
    on: {
      "update:form": function updateForm($event) {
        _vm.upsertItem = $event;
      },
      "submit-disabled": _vm.submitDisabled
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }