<template>
  <div>
    <div class="card card-custom">
      <div class="card-header border-0 pt-6 pb-0">
        <div class="card-title align-items-start flex-column">
          <h3 class="card-label font-weight-bolder">
            {{ $t("Address") }}
          </h3>
          <span class="text-muted font-weight-bold font-size-sm mt-1">{{ $t("Update your addresses") }}</span>
        </div>
        <div class="card-toolbar">
          <button class="btn btn-light-primary font-weight-bolder py-3" @click.prevent="openCreateDialog">
            <span class="svg-icon menu-icon">
              <inline-svg src="/media/svg/icons/Files/File.svg" />
            </span>
            {{ $t("New address") }}
          </button>
        </div>
      </div>
      <div class="card-body detail">
        <address-table :items="physicalPerson.physicalpersonaddress_set" :kinds="addressKinds" @edit="openEditDialog"
          @remove="openRemoveDialog"></address-table>
      </div>
    </div>
    <validation-observer ref="validationObserver">
      <b-modal ref="upsertModal" size="lg" hide-footer @hidden="onUpsertModalHidden">
        <template #modal-header>
          <div class=" w-100 h-100 d-flex justify-content-between align-items-center px-4 py-1">
            <h3>{{ $t("Select an address") }}</h3>
            <div class="d-flex align-items-center">
              <b-button v-if="!disabled" variant="success" class="mr-2"
                :class="{ 'spinner spinner-light spinner-right': isSaving }" @click="submit">
                {{ upsertItem.id ? $t("Update") : $t("Create") }}
              </b-button>
              <b-button @click="closeUpsertModal">
                {{ $t('Cancel') }}
              </b-button>
            </div>
          </div>
        </template>
        <AddressForm :form.sync="upsertItem" :kinds="addressKinds" @submit-disabled="submitDisabled"></AddressForm>
      </b-modal>
    </validation-observer>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import PhysicalPersonAddressService from "@/core/services/physical-person/physical-person-address.service";
import AddressTable from "@/view/components/tables/AddressTable.vue";
import AddressForm from "@/view/components/forms/AddressForm.vue";
import icons from "@/core/config/icons.js";
import { addressValidationSwal, backendErrorSwal, deleteSwal } from "@/core/helpers/swal";

const DEFAULT_UPSERT_ITEM = {
  main: false,
  address: null,
  description: "",
  kind: "0",
  manual_address: false,
  composed_address: null
};
export default {
  components: {
    AddressTable,
    AddressForm,
  },

  props: {
    physicalPerson: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      icons,
      apiErrors: null,
      upsertItem: { ...DEFAULT_UPSERT_ITEM },
      isSaving: false,
      addressKinds: [],
      canContinue: true,
      disabled: true
    };
  },

  computed: {
    requestBody() {
      let req = { ...this.upsertItem };
      req.persona = this.physicalPerson.id;
      return req;
    },
  },

  watch: {
    apiErrors: {
      deep: true,
      handler(val) {
        const formObs = this.$refs.validationObserver;
        const newErrors = {
          main: [...(val.main ?? [])],
          address: [...(val.address.raw ?? [])],
          description: [...(val.description ?? [])],
        };
        formObs.setErrors({ ...newErrors });
      },
    },
  },

  created() {
    this.loadKinds();
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("Physical person"), route: { name: "manage-physical-person" } },
      { title: this.$t("Address") },
    ]);
  },

  methods: {
    async submit() {
      this.canContinue = true;
      const validator = this.$refs.validationObserver;
      if (validator) {
        await validator.reset();
        const isValid = await validator.validate();
        if (!isValid) {
          await addressValidationSwal().then(res => {
            if (!res.isConfirmed) {
              this.canContinue = false;
            }
          });
        }
      }

      if (this.canContinue) {
        // It's an update
        if (this.upsertItem.id) {
          if (this.upsertItem.main) {
            const exMain = await this.physicalPerson.physicalpersonaddress_set.find(
              el => el.main && el.id !== this.upsertItem.id,
            );
            if (exMain) exMain.main = false;
          }
          await this.update(this.requestBody, this.upsertItem.id);
          return;
        }

        await this.create(this.requestBody);
      }
    },

    async update(body, id) {
      this.isSaving = true;
      try {
        await PhysicalPersonAddressService.update(body, id);
        this.$emit("updated");
        this.closeUpsertModal();
      } catch (err) {
        if (err?.response?.status === 400) {
          this.apiErrors = err.response.data;
        } else {
          backendErrorSwal(err?.response?.data?.detail, err);
        }
      } finally {
        this.isSaving = false;
      }
    },

    async create(body) {
      this.isSaving = true;
      try {
        await PhysicalPersonAddressService.create(body);
        this.$emit("updated");
        this.closeUpsertModal();
      } catch (err) {
        if (err?.response?.status === 400) {
          this.apiErrors = err.response.data;
        } else {
          backendErrorSwal(err?.response?.data?.detail, err);
        }
      } finally {
        this.isSaving = false;
      }
    },

    async openEditDialog(item) {
      this.disabled = true;
      this.upsertItem = { ...item };
      this.$refs.upsertModal.show();
    },

    openCreateDialog() {
      this.disabled = true;
      this.$refs.upsertModal.show();
    },

    openRemoveDialog(item) {
      deleteSwal().then(async res => {
        if (res.isConfirmed) {
          try {
            await PhysicalPersonAddressService.delete(item);
            this.$emit("updated");
          } catch (err) {
            backendErrorSwal(err?.response?.data?.detail, err);
          }
        }
      });
    },

    onUpsertModalHidden() {
      this.apiErrors = null;
      this.upsertItem = { ...DEFAULT_UPSERT_ITEM };
    },

    closeUpsertModal() {
      this.$refs.upsertModal.hide();
    },

    async loadKinds() {
      this.addressKinds = await PhysicalPersonAddressService.getKinds();
    },

    submitDisabled(status) {
      this.disabled = status;
    }
  },
};
</script>
